import { getProfileInfo } from '../../util/api/methodsUsers'
import { login } from '../../util/api/methodsLogin';
import { saveToken, saveUser } from '../../util/localStorageHandling/localStorageMethods';

export const submitLogin = async (body) =>{
    try {
        const response = await login(body); 
        if(response.data.status === 1){
            const user = response.data.response;
            saveToken(user.token);
            await getProfileInfo()
                .then(data => saveUser(data.data.response))
                .catch(error => saveUser(error));
            return true;
        }
        else
        {
            return false;
        }        
    } catch (error) {        
        return false;
    }      
}