import { Button, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from '../../util/StyleMUI';
import { deletePkgOnwer } from '../../util/api/methodsPackageOwner';

const TableRowPkgOwner = ({edit, getData, row, index, columns}) => {
    const classes = useStyles();
    var id;
    const tableCellFunc = (column, value) =>{  
        if(column.id === 'id'){
            id = value;
        }
        switch (column.id) {
            case 'user':
                return value.map(user => user.profileName + ', ');
            case 'edit':
                return <>
                    <Button 
                        className={classes.deleteButton} 
                        style={{marginRight: '20px'}}
                        onClick={() => {
                            if (window.confirm('Tem certeza?'))
                            handleClickD(getData, id)}}>
                            <DeleteIcon/>
                    </Button>
                    <Button                         
                        className={classes.editButton} 
                        onClick={()=>handleEdit(id)}>
                            <EditIcon/>
                    </Button>
                </>;
            default:
                return value;
        }
    }

    const handleEdit = async (id) => {
        await edit(id);
    }

    const handleClickD = async (getData, id) => {
        await deletePkgOnwer(id);
        await getData();
    }

    return (
        <>
            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
            {columns.map((column) => {
                const value = row[column.id];
                return (
                <TableCell key={column.id} align={column.align}>
                    {tableCellFunc(column, value)}
                </TableCell>
                );
            })}
            </TableRow>                
        </>
    )
}

export default TableRowPkgOwner
