
import React, {useState, useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { ThemeProvider } from '@material-ui/styles';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import {userItens, adminItems} from './SideMenuItens';
import {theme, themeAlter} from '../../util/Theme';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { getUser, saveUser } from '../../util/localStorageHandling/localStorageMethods';
import { Button, MenuItem, Menu} from '@material-ui/core';
import { getPkgOnwer } from '../../util/api/methodsPackageOwner';

var initialUser = {
  username:'',
  profileName: '',
  packageOwner:{
    name: ''
  }
}

var initialPkgOwnerList = [{id:0, name:'', }]

export const SideBar = ({classes, logOff, loggedIn}) => {
  const [user, setUser] = useState(initialUser);
  const location = useLocation();
  const [pkgOwnerList, setPkgOwnerList] = useState(initialPkgOwnerList);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {    
    setAnchorEl(null); 
  };

  useEffect(() => {
    let mounted = true;
    getPkgOnwer().then(
      data => {
        if(mounted){
          setPkgOwnerList(data.response);
        }
      }
    )
    return ()=>mounted=false;
  }, [])
    
  useEffect( () =>{
    if(loggedIn){
      getUser().then(data => {
        if(data != null || typeof data != 'undefined'){
          setUser(data);
        }
      });
    }else{
      setUser(initialUser);
    }    
  }, [loggedIn]);

  const toolBarLocation = (path) =>{
    switch (path) {
      case '/home':
        return <Typography style={{width:'100%'}}  variant="h6" noWrap >Home</Typography>;
      case '/profile':
        return <Typography style={{width:'100%'}}  variant="h6" noWrap >Perfil</Typography>; 
      case '/pkg':
        return <Typography style={{width:'100%'}} variant="h6" noWrap >Pacotes</Typography>;
      case '/pkgowner':
        return <Typography style={{width:'100%'}} variant="h6" noWrap >Programadoras</Typography>;
      case '/vendors':
        return <Typography style={{width:'100%'}} variant="h6" noWrap >Vendors</Typography>;
      case '/users':
        return <Typography style={{width:'100%'}} variant="h6" noWrap >Usuários</Typography>;
      default:
          break;
    }
  }

  const changePkgOnwer = async (id, name) => {   
    setAnchorEl(null); 
    var userTemp = await getUser();
    userTemp.packageOwner.id = id;
    userTemp.packageOwner.name = name;
    await saveUser(userTemp);
    setUser(userTemp);
    window.location.reload();
  }

  const changeMenu = () => {
    return <><ThemeProvider theme={themeAlter} >
      <Typography style={{width: '100%'}} >
        <Button color='primary' style={{float: 'right', color:'#fff'}} variant='outlined' onClick={handleClick}> {user.packageOwner.name} </Button>   
      </Typography>
      </ThemeProvider>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {pkgOwnerList.map(pkg => <MenuItem key={pkg.id} onClick={() => changePkgOnwer(pkg.id, pkg.name)}>{pkg.name}</MenuItem>)}
      </Menu></>                   
  }

  return (
    <Typography component="div" className={classes.root}  >
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
      <Toolbar  style={theme.palette.primary}>        
          {toolBarLocation(location.pathname)}  
          {user.admin === 1 && location.pathname === '/home' ? changeMenu():''}            
      </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      >
      <Typography style={{minHeight:'160px'}} component="div" className={classes.tester}>
        <PermIdentityIcon style={{fontSize: 50}}/>
        <Typography component="h1" style={{fontSize: 20}}> {user.username} </Typography>
        <Typography component="h2" style={{fontSize: 12}}> {user.profileName} </Typography>
        <Typography component="h2" style={{fontSize: 12}}> {user.packageOwner.name} </Typography>
      </Typography>
      <Divider />
      <List>
        {userItens.map((item, index) => (
        <ListItem selected={location.pathname === item.path} button key={index} component={Link} to={item.path}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText style={location.pathname === item.path ? {color: '#880'}: {color: ''}}  primary={item.title} />
        </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {
          user.admin === 1 ? 
          adminItems.map((item, index) => (
          <ListItem button selected={location.pathname === item.path} key={index} component={Link} to={item.path}>
            <ListItemIcon >{item.icon}</ListItemIcon>
            <ListItemText style={location.pathname === item.path ? {color: '#880'}: {color: ''}}  primary={item.title} />
          </ListItem>
          ))
          :
          ''
        }
      </List>
      <List style={{marginTop:'auto'}}>
        <Divider />
        <ListItem button onClick={logOff}>
          <ListItemIcon>
            <ExitToAppIcon style={theme.palette.secondary}/>
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </ListItem>
      </List>
      </Drawer>
    </Typography>
  )
}
