import React, { useEffect, useState } from 'react';
import TableRowPkgOwner from '../../Components/ComponentTableCell/TableRowPkgOwner';
import { Button, Container, Divider, Paper, TextField, Typography } from '@material-ui/core';
import TableContent from '../../Components/TableContent';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { theme } from '../../util/Theme';
import { getPkgOnwer, getPkgOnwerById } from '../../util/api/methodsPackageOwner';
import PkgOwnerEdit from './PkgOwnerEdit';
//------------------------
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'user', label: 'Usuários', minWidth: 50 },
    { id: 'edit', label: 'Editar', minWidth: 200, align: 'center' },
  ];
//------------------------


const PkgOwner = ({classes}) => {

    const [pkgOwner, setPkgOwner] = useState([]);
    const [pkgOwnerTemp, setPkgOwnerTemp] = useState([]);
    const [found, setFound] = useState(true);
    const [edit, setEdit] = useState(true);
    const [pkgOwnerEdit, setPkgOwnerEdit] = useState(0);
    
    useEffect(() => {
        getData().then(data => {
            setPkgOwner(data.response);
            setPkgOwnerTemp(data.response);
        }).catch(
            error =>
            console.log(error)
        );        
    }, [edit]);
    
    const getData = async () =>{
        const data = await getPkgOnwer();
        setPkgOwner(data.response);
        setPkgOwnerTemp(data.response);
        return data;
    }

    
    const handleSubmit = async (event) =>{
        event.preventDefault(); 
        const value = event.target.value;
        if(value === ''){
            setPkgOwnerTemp(pkgOwner);
        }else{
            var search = pkgOwnerTemp.filter(d => d.name.toUpperCase().indexOf(value.toUpperCase()) > -1 );
            if(search.length === 0){
                setFound(false);
            }else{
                setPkgOwnerTemp(search);
                setFound(true);
            }
        }
    }

    const handledAdd = async () => {
        await setPkgOwnerEdit({
            name: '',
            id: 0,
        })
        await setEdit(false);
    }

    const handleEdit = async (id) => {
        const data = await getPkgOnwerById(id);
        await setPkgOwnerEdit(data.response);
        await setEdit(false);
    }

    return (
        <main className={classes.content}>
            <div className={classes.toolbar}>
                {edit ?
                    <>                
                    <Paper className={classes.tableHome}>
                        <Container  style={{padding: '20px', position: 'relative', display: 'inline-block'}}>
                            <SearchIcon style={{position: 'absolute', left: 200, top: 50, width: 20, height: 20}}/> 
                            <TextField                             
                                onChange={handleSubmit}
                                autoComplete='off'
                                id='search'
                                label='Pesquisar' 
                                placeholder='...'
                                style={{display: 'inline-flex', margin: 8}} 
                                InputLabelProps={{
                                    shrink: true,
                                }}/> 
                                <Button 
                                    onClick={handledAdd}
                                    startIcon={<AddIcon/>}
                                    className={classes.addButton}
                                    style={theme.palette.primary}
                                >  
                                    Adicionar
                                </Button>
                        </Container>
                        {found ? <TableContent Cell={TableRowPkgOwner} edit={handleEdit} getData={getData} classes={classes} rows={pkgOwnerTemp} columns={columns} /> : 
                        <>
                        <Divider/>
                        <Typography className={classes.notFound}>Nenhum resultado encontrado!</Typography>
                        </> }
                    </Paper></>
                    :
                    <PkgOwnerEdit getData={getData} setEdit={setEdit} pkgOwner={pkgOwnerEdit}/>
                    }                                
            </div>
        </main>
    )
}

export default PkgOwner
