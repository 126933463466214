import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import React, {useState} from 'react';
import { theme } from '../../util/Theme';
import { Link } from "react-router-dom";
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import { submitEmail } from './submitEmail';

const Email = ({classes}) => {
    const [sent, setSent] = useState(false)
    const handleSubmit = async (event) => {
        event.preventDefault();
        const email = event.target.email.value;        
        await submitEmail(email);
        setSent(true);
    }
    return (        
        <div className={classes.email}>
            <Paper style={{width: '450px', height: '350px', display: 'inline-block'}}>
                {sent ? 
                <div>
                    <PresentToAllIcon fontSize='large' style={{marginTop: '150px'}}/>
                    <Typography  variant='h6'>Email enviado!</Typography>
                    <br/>
                    <Grid container>
                        <Grid item xs component={Link} to='/login'>
                            <Typography variant="body2" style={theme.palette.text}>
                                Voltar para o login
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                :
                <form  className={classes.formEmail} onSubmit={handleSubmit}>
                    <Typography variant='h6'>
                        Informe seu email que iremos enviar instruções para a alteração de senha!
                    </Typography>
                    <br/>
                    <br/>
                    <TextField 
                        variant='outlined' 
                        id='email'
                        fullWidth
                        placeholder='exemplo@email...'
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                    />
                    <Button 
                        type='submit'
                        fullWidth
                        variant='contained'
                        style={theme.palette.butons}
                        className={classes.submit} >
                        Enviar
                    </Button>
                    <Grid container>
                    <Grid item xs component={Link} to='/login'>
                        <Typography variant="body2" style={theme.palette.text}>
                        Voltar para o login
                        </Typography>
                    </Grid>              
                    </Grid>
                </form>
                }
            </Paper>        
        </div>
    )
}

export default Email
