import { Button, Container, Divider, Paper, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import TableRowVendor from '../../Components/ComponentTableCell/TableRowVendor';
import { getVendorById, getVendors } from '../../util/api/methodsVendor';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { theme } from '../../util/Theme';
import TableContent from '../../Components/TableContent';
import VendorEdit from './VendorEdit';

//------------------------
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 50, align: 'center' },
    { id: 'userToken', label: 'Usuário MW', minWidth: 50 },
    { id: 'token', label: 'Secret(API token)', minWidth: 80 },
    { id: 'url', label: 'Url MW', minWidth: 80 },
    { id: 'edit', label: 'Editar', minWidth: 200, align: 'center' },
  ];
//------------------------

const Vendor = ({classes}) => {

    const [vendor, setVendor] = useState([]);
    const [vendorTemp, setVendorTemp] = useState([]);
    const [vendorEdit, setVendorEdit] = useState([]);
    const [found, setFound] = useState(true);
    const [edit, setEdit] = useState(true);

    useEffect(() => {
        getData().then(data => {
            setVendor(data.response);
            setVendorTemp(data.response);
        }).catch(
            error =>
            console.log(error)
        );        
    }, []);
    
    const getData = async () =>{
        const data = await getVendors();
        setVendor(data.response);
        setVendorTemp(data.response);
        return data;
    }

    
    const handleSubmit = async (event) =>{
        event.preventDefault(); 
        const value = event.target.value;
        if(value === ''){
            setVendorTemp(vendor);
        }else{
            var search = vendorTemp.filter(d => d.name.toUpperCase().indexOf(value.toUpperCase()) > -1 );
            
            if(search.length === 0){
                setFound(false);
            }else{
                setVendorTemp(search);
                setFound(true);
            }
        }
    }

    const handledAdd = async () => {
        await setVendorEdit({
            id: 0,
            name: '',
            userToken: '',
            token: '',
            url: '',
        });
        await setEdit(false);
    }

    const handleEdit = async (id) => {
        const response = await getVendorById(id);
        await setVendorEdit( response.response );
        await setEdit(false);
    }
    
    return (
        <main className={classes.content}>
            <div className={classes.toolbar}>
                {edit ?
                    <>
                    <Paper className={classes.tableHome}>
                        <Container  style={{padding: '20px', position: 'relative', display: 'inline-block'}}>
                            <SearchIcon style={{position: 'absolute', left: 200, top: 50, width: 20, height: 20}}/> 
                            <TextField                             
                                onChange={handleSubmit}
                                autoComplete='off'
                                id='search'
                                label='Pesquisar' 
                                placeholder='...'
                                style={{display: 'inline-flex', margin: 8}} 
                                InputLabelProps={{
                                    shrink: true,
                                }}/> 
                                <Button 
                                    onClick={handledAdd}
                                    startIcon={<AddIcon/>}
                                    className={classes.addButton}
                                    style={theme.palette.primary}
                                >  
                                    Adicionar
                                </Button>
                        </Container>
                        {found ? <TableContent Cell={TableRowVendor} edit={handleEdit} getData={getData} classes={classes} rows={vendorTemp} columns={columns} /> : 
                        <>
                        <Divider/>
                        <Typography style={{fontWeight: 'bold', marginTop: '10px', display: 'flex', justifyContent: 'center' }}>Nenhum resultado encontrado!</Typography>
                        </> }
                    </Paper></>
                    :
                    <VendorEdit getData={getData} setEdit={setEdit} vendorEdit={vendorEdit}/>
                    }
            </div>
        </main>
    )
}

export default Vendor
