import React from 'react';
import { theme } from '../../util/Theme';
import { Button, TableCell, TableRow } from '@material-ui/core';
import { changeStatus } from '../../util/api/methodsPackagesManagement';

const TableRowHome = ({getData, row, index, columns}) => {
    var enable;
    var vendor;
    const tableCellFunc = (column, value) =>{  
        if(column.id === 'id'){
            vendor = value;
        }
        if(column.id === 'packages'){
            enable = value[0].isEnabled === 1 ? true : false;
            return (value.map(p => p.name + ' - '))
        }else{
            if(column.id === 'ativo'){ 
                if(enable){
                    return <Button 
                            style={theme.palette.butonsDeactivate} 
                            onClick={() => {
                                 if (window.confirm('Tem certeza?'))
                                  handleChange(getData, vendor, 0) } 
                                }>
                                    Desativar
                            </Button>;
                }else{
                    return <Button 
                            style={theme.palette.butonsActivate} 
                            onClick={() => {
                                 if (window.confirm('Tem certeza?'))
                                  handleChange(getData, vendor, 1) } 
                                }>
                                    Ativar
                            </Button>;
                }
            }else{
                return value;
            }
        }
    }

    const handleChange = async (getData, vendor, status) => {
        try {
            //console.log(vendor, status);
            const response = await changeStatus(getData, vendor, status);
            if(response.status === 1){
                await getData();
            }else{
                console.log(response);
            }           
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
            {columns.map((column) => {
                const value = row[column.id];
                return (
                <TableCell key={column.id} align={column.align}>
                    {tableCellFunc(column, value)}
                </TableCell>
                );
            })}
            </TableRow>                
        </>
    )
}

export default TableRowHome
