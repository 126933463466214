import { getToken } from "../localStorageHandling/localStorageMethods";
import { apiCall, EMAIL, GET, LOGIN, POST, RESET } from "./apiConstants";
import { getProfileInfo } from "./methodsUsers";

export const login = async (body) => {
    try {
        const response = await apiCall(LOGIN, '', body, POST);
        return response;
    } catch (error) {
        return error;
    }
}

export const sendEmail = async (email) => {
    try {
        const response = await apiCall(EMAIL+email, '', '', GET);
        return response;
    } catch (error) {
        return error;
    }
}

export const resetPassword = async (token, password ) => {
    const body = {
        token: token,
        password: password,
    }
    try {
        const response = await apiCall(RESET, '', body, POST);
        return response;
    } catch (error) {
        return error;
    }
}

export const isTokenValide = async () => {
    var token = getToken();
    //console.log(token)
    if(token === null){
        //console.log(false)
        window.localStorage.clear();
        return false;
    }else{
        try{
            const response = await getProfileInfo();
            //console.log(response);
            if(response.data.status !== 1){
                //console.log(false)
                window.localStorage.clear();
                return false;
            }
            //console.log(true);
            return true;
        }catch(error){
            //console.log(error);
            window.localStorage.clear();
            return false;
        }
    }        
}