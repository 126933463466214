import { createTheme } from "@material-ui/core";

const preto = '#161311'; //2b2522 default
const amarelo = '#ffce00';
const branco = '#fff';

export const themeAlter = createTheme({
    palette: {        
        primary:{
            main: branco,
        },
        secondary:{
            main: amarelo,
        }
    }
})

export const theme = {
    palette: {
        primary: { 
            backgroundColor: preto,
            color: branco,
        },
        secondary: { 
            backgroundColor: branco,
            color: amarelo,
        },
        butons: {
            backgroundColor: amarelo,
            color: branco,
        },
        buttonDisabled: {
            backgroundColor: '#d6d6d6',
            color: branco,
        },
        buttonSwitch: {
            color: branco,
        },
        butonsActivate: {
            backgroundColor: '#59f086',
            color: branco,
            maxWidth: '100px', 
            maxHeight: '30px', 
            minWidth: '100px', 
            minHeight: '30px'
        },   
        butonsDeactivate: {
            backgroundColor: '#f06359',
            color: branco,
            maxWidth: '100px', 
            maxHeight: '30px', 
            minWidth: '100px', 
            minHeight: '30px'
        },       

        text: { 
            color: preto,
        },
    },
};