import { getToken } from "../localStorageHandling/localStorageMethods";
import { apiCall, PROFILE, USER , DELETE, GET, POST, PUT } from "./apiConstants";

export const getProfileInfo = async () => {
    var token = await getToken();
    try {
        const response = await apiCall( PROFILE, {Authorization: token},'', GET );
        return response;
    } catch (error) {
        return false;
    }
}

export const editProfile = async (body) => {
    var token = await getToken();
    try {
        const response = await apiCall( PROFILE, {Authorization: token},body, POST );
        return response.data;
    } catch (error) {
        return false;
    }
}

export const getUsers = async (body) => {
    var token = await getToken();
    try {
        const response = await apiCall( USER, {Authorization: token},body, GET );
        return response.data;
    } catch (error) {
        return false;
    }
}


export const getUserById = async (id) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(USER+'/'+id, {Authorization: token}, '', GET)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const saveUser = async (body) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(USER, {Authorization: token}, body, POST)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const updateUser = async (body) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(USER, {Authorization: token}, body, PUT)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const deleteUser = async (id) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(USER+'?id='+id, {Authorization: token}, '', DELETE);
        return data;
    } catch (error) {
        console.log(error);
    }
}