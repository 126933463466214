export const getToken = async () =>{
    return window.localStorage.getItem('token');
}

export const getUser = async () =>{
    return JSON.parse(window.localStorage.getItem('user'));
}

export const saveUser = async (data) => {
    window.localStorage.setItem('user', JSON.stringify(data));
}

export const saveToken = async (data) => {
    window.localStorage.setItem('token', data);
}

export const clearStorage = async () => {
    window.localStorage.clear();
}