import { useLayoutEffect, useState } from "react";
import { SideBar } from "../Components/sideBar/SideBar";
import useStyles from '../util/StyleMUI';
import {
    Switch,
    Route,
    useHistory,
    useLocation,
    Redirect
  } from "react-router-dom";
import Home from "../containers/Pages/Home";
import Profile from "../containers/Pages/Profile";
import SignIn from "../containers/Pages/Login";
import { isTokenValide } from "../util/api/methodsLogin";
import { clearStorage } from "../util/localStorageHandling/localStorageMethods";
import logo from '../resource/load-icon.png'
import '../util/App.css'
import Pkg from "./Pages/Pkg";
import Email from "./Pages/Email";
import Reset from "./Pages/Reset";
import PkgOwner from "./Pages/PkgOwner";
import Vendor from "./Pages/Vendor";
import Users from "./Pages/Users";

function Main() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();  
    const [loaded, setLoaded] = useState(false)
    const [logged, setLogged] = useState(false);
      
    useLayoutEffect ( () => {
      isTokenValide().then(data => {
        console.log("useLayout");
        setLogged(data);
        setLoaded(true);
        })
    }, [])
    
    const makeLogin = async ()=>{
      setLogged(true);
    }
    
    const logOff=()=>{
      clearStorage();
      setLogged(false);
      history.push('/login')
    }
    return (
        <>
        {loaded ?         
        //----------------
        <>
        <div className='back'>    
        {
            location.pathname === '/login' || 
            location.pathname === '/email' ||
            location.pathname === '/reset' ?
            '' :
            (logged? <SideBar classes={classes} logOff={logOff} loggedIn={logged}/>: '')
        }          
        <Switch>

            <Route path="/login">
            {logged ? <Redirect to='/home'/> : <SignIn classes={classes} login={makeLogin}/>}
            </Route>

            <Route exact path="/email">
            {logged ? <Redirect to='/home'/> : <Email classes={classes} />}
            </Route>

            <Route exact path="/reset">
            {logged ? <Redirect to='/home'/> : <Reset classes={classes} />}
            </Route>

            <Route exact path="/home">
            {logged ? <Home classes={classes}/> : <Redirect to='/login'/>}
            </Route>

            <Route path="/profile">
            {logged ? <Profile classes={classes} /> : <Redirect to='/login'/>}
            </Route>

            <Route path="/pkgowner">
            {logged ? <PkgOwner classes={classes} /> : <Redirect to='/login'/>}
            </Route>

            <Route path="/vendors">
            {logged ? <Vendor classes={classes} /> : <Redirect to='/login'/>}
            </Route>

            <Route path="/pkg">
            {logged ? <Pkg classes={classes} /> : <Redirect to='/login'/>}
            </Route>

            <Route path="/users">
            {logged ? <Users classes={classes} /> : <Redirect to='/login'/>}
            </Route>

            <Route path='*'>
            <Redirect to='/login'/>
            </Route>

        </Switch>
        </div>
        </>
        //----------------
        :         
        <div className="App">      
            <img src={logo} className="App-logo" alt="logo" />    
        </div>}
        </>           
    );
}

export default Main;

