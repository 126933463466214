import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 220;

const useStyles = makeStyles((themes) => ({  
  avatar: {
    height: '38px',
    width: '50px',
    marginTop: '20px',
    marginBottom: '10px',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  addButton:{
    float:'right',
    marginTop: '10px',
  },
  content: {   
    overflow: 'scroll',
    position: 'fixed',
    padding: themes.spacing(3),
    paddingLeft: drawerWidth,
    width: '100vw',
    height: '100vh',
    flexGrow: 1, 
    backgroundColor: '#C5C4C4',
    ...themes.mixins.toolbar,
  },
  containerHome: {
    maxHeight: 440,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  editButton: {
    backgroundColor: '#59f086',
    color: 'yellow',
    "&:hover": {
      backgroundColor: '#7eed9f',
      color: 'black',
    }
  },   
  deleteButton: {
    backgroundColor: '#f06359',
    color: 'yellow',
    "&:hover": {
      backgroundColor: '#f0a19c',
      color: 'black',
    }
  },
  editBack:{
    minWidth: '600px', 
    width: '70%', 
    margin: 'auto', 
    paddingTop: '50px',
    paddingBottom: '50px'
  },    
  edit:{
    minWidth: '400px',
    width: '80%',
    margin: 'auto',
    padding: '10px',
  },
  editPaper:{
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },   
  email:{    
    paddingTop: '20vh',
    textAlign: 'center',
  },
  form: {
    width: '75%', // Fix IE 11 issue.
    marginTop: themes.spacing(1),
  },    
  formEmail: {
    marginTop: '50px',
    display: 'inline-block',
    width: '350px',
  },
  login:{
    alignItems:'center', 
    display: 'flex', 
    flexDirection: 'column', 
    width: '450px', 
    height: '500px' 
  },
  notFound:{
    fontWeight: 'bold', 
    marginTop: '10px', 
    display: 'flex', 
    justifyContent: 'center',
  },
  perfil:{
    minWidth: '400px',
    width: '50%',
    margin: 'auto',
    padding: '10px'
  },
  paper: {
    paddingTop: themes.spacing(9),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paperPerfil: {
    padding: '20px',
    marginTop: themes.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
  },  
  submit: {
    margin: themes.spacing(3, 0, 2),
  },
  submitEmail: {
    background: '#ffce00',
    margin: themes.spacing(3, 0, 2),
    color: '#000',
    '&hover':{
      background: '#fcdf65',
    }
  },
  tableHome: {
    width: '80%',
    margin: 'auto',
    marginBottom: '50px'
  },  
  // necessary for content to be below app bar
   
  tester:{
    ...themes.mixins.toolbar,
    textAlign: 'center',
    padding: themes.spacing(3),
  },
  toolbar: {
    minWidth: '500px',
    paddingTop: '25px',
  } 
}));

export default useStyles;