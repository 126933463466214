import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import React, {useState} from 'react';
import { theme } from '../../util/Theme';
import { Link } from "react-router-dom";
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import { resetPassword } from '../../util/api/methodsLogin';

const Reset = ({classes}) => {
    const [changed, setChanged] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [errorField, setErrorField] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');


    const setPassword1 = async (event) => {
        setPasswordOne(event.target.value);
        if(passwordTwo !== event.target.value){
            setIsDisabled(true);
            setErrorField(true);
            setErrorText('Senhas não são iguais!');
        }else if(event.target.value === ''){
            setIsDisabled(true);
            setErrorField(true);
            setErrorText('Senhas não são iguais!');
        }
        else{
            setIsDisabled(false);
            setErrorField(false);
            setErrorText('');
        }
    }

    const handleConfirm = (event) => {
        setPasswordTwo(event.target.value);
        if(passwordOne !== event.target.value){
            setIsDisabled(true);
            setErrorField(true);
            setErrorText('Senhas não são iguais!');        
        }else if(event.target.value === ''){
            setIsDisabled(true);
            setErrorField(true);
            setErrorText('Senhas não são iguais!');
        }
        else{
            setIsDisabled(false);
            setErrorField(false);
            setErrorText('');
        }        
    }

    const handleSubmit = async (event) =>{        
        event.preventDefault();
        const search = new URLSearchParams(window.location.search);
        const token = search.get('token');
        const {data} = await resetPassword(token, passwordOne);
        if(data.status === 1){
            setChanged(true);
        }else{
            window.alert('Erro ao mudar senha! Contate o administrador do sistema!');
        }
    }
    return (
        <div className={classes.email}>
            <Paper elevation={3} style={{width: '500px', height: '400px', display: 'inline-block'}}>
                {changed ? 
                <div>
                    <PresentToAllIcon fontSize='large' style={{marginTop: '150px'}}/>
                    <Typography  variant='h6'>Senha alterada!</Typography>
                    <br/>
                    <Grid container>
                        <Grid item xs component={Link} to='/login'>
                            <Typography variant="body2" style={theme.palette.text}>
                                Voltar para o login
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                :
                <form  className={classes.formEmail} onSubmit={handleSubmit}>
                    <Typography variant='h6'>
                        Informe uma nova senha:
                    </Typography>
                    <br/>
                    <br/>
                    <TextField 
                        label='Senha'
                        type='password'
                        variant='outlined' 
                        id='password'
                        onChange={setPassword1}
                        fullWidth
                        placeholder='**********'
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                    />
                    <TextField 
                        label='Confirme a sua senha'
                        style={{marginTop: '10px'}}
                        type='password'
                        variant='outlined' 
                        id='confirm'
                        fullWidth
                        placeholder='**********'
                        onChange={handleConfirm}
                        error={errorField}
                        helperText={errorField ? errorText : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                    />
                    <Button 
                        disabled={isDisabled}                        
                        type='submit'
                        fullWidth
                        variant='contained'
                        style={!isDisabled?theme.palette.butons:theme.palette.buttonDisabled}
                        className={classes.submit} >
                        Alterar
                    </Button>
                    <Grid container>
                    <Grid item xs component={Link} to='/login'>
                        <Typography variant="body2" style={theme.palette.text}>
                        Voltar para o login
                        </Typography>
                    </Grid>              
                    </Grid>
                </form>
                }
            </Paper>        
        </div>
    )
}

export default Reset
