import { getToken } from "../localStorageHandling/localStorageMethods";
import { apiCall, PACKAGE , DELETE, GET, POST, PUT } from "./apiConstants";

export const getPackages = async () => {
    const token = await getToken();
    try{
        const data = await apiCall(PACKAGE, {Authorization: token},'', GET);
        return data.data;
    }catch(error){
        return error;
    }
}

export const getPackageById = async (id) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(PACKAGE+'/'+id, {Authorization: token}, '', GET)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const getMotvPackages = async (vendor) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(PACKAGE+'/pairs?vendor='+vendor, {Authorization: token}, '', GET)
        return data;
    } catch (error) {
        console.log(error);
    }
}


export const savePackage = async (body) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(PACKAGE, {Authorization: token}, body, POST)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const updatePackage = async (body) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(PACKAGE, {Authorization: token}, body, PUT)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const deletePackage = async (id) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(PACKAGE+'?id='+id, {Authorization: token}, '', DELETE);
        return data;
    } catch (error) {
        console.log(error);
    }
}