import axios from "axios";


export const URL  = 'https://programadorasapi.youcast.tv.br/';
export const PKG_MGNT = 'api/pkg/management';
export const USER = 'api/pkg/user';
export const EMAIL = 'api/pkg/password?email=';
export const RESET = 'api/pkg/password';
export const PROFILE = 'api/pkg/user/profile';
export const PKG_OWNER = 'api/pkg/programadora';
export const VENDOR = 'api/pkg/vendor';
export const PACKAGE = 'api/pkg/package';
export const LOGIN = 'login';
export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const DELETE = 'delete';

export const apiCall = async (url, header ,body, method) =>{    
        const response = await axios({
            url: URL+url,		
            method: method,
            headers: header,
            data: body,
            validateStatus: (status) => status >=200 && status <500,
        }).then(data => data).catch(data => data);            
        //console.log(response);
        return response;		
}

