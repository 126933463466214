import { Button, Container, Divider, Paper, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TableContent from '../../Components/TableContent';
import SearchIcon from '@material-ui/icons/Search';
import TableRowPkg from '../../Components/ComponentTableCell/TableRowPkg';
import { getPackageById, getPackages } from '../../util/api/methodsPackages';
import AddIcon from '@material-ui/icons/Add';
import { theme } from '../../util/Theme';
import PkgEdit from './PkgEdit';
//------------------------
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'mwId', label: 'MW ID', minWidth: 50, align: 'center' },
    { id: 'packageOwner', label: 'Programadora', minWidth: 50, align: 'center' },
    { id: 'vendor', label: 'Vendor', minWidth: 50, align: 'center' },
    { id: 'edit', label: 'Editar', minWidth: 200, align: 'center' },
  ];
//------------------------

const Pkg = ({classes}) => {

    const [packages, setPackages] = useState([]);
    const [packagesTemp, setPackagesTemp] = useState([]);
    const [packageTemp, setPackageTemp] = useState([]);
    const [found, setFound] = useState(true);
    const [edit, setEdit] = useState(true);

    useEffect(() => {
        getData().then(data => {
            setPackages(data.response);
            setPackagesTemp(data.response);
        }).catch(
            error =>
            console.log(error)
        );        
    }, [edit]);
    
    const getData = async () =>{
        const data = await getPackages();
        setPackages(data.response);
        setPackagesTemp(data.response);
        return data;
    }

    const handleSubmit = async (event) =>{
        event.preventDefault(); 
        const value = event.target.value;
        if(value === ''){
            setPackagesTemp(packages);
        }else{
            var search = packagesTemp.filter(d => d.name.toUpperCase().indexOf(value.toUpperCase()) > -1 );
            
            if(search.length === 0){
                setFound(false);
            }else{
                setPackagesTemp(search);
                setFound(true);
            }
        }
    }

    const handledAdd = async () => {
        await setPackageTemp({
            id: 0,
            name: '',
            mwId: 0,
            isEnabled: 1,
            packageOwner: {
                id: 0,
                name: '',
            },
            vendor: {
                id: 0,
                name: '',
                url: '',
            }
        });
        await setEdit(false);
    }

    const handleEdit = async (id) => {
        const response = await getPackageById(id);
        await setPackageTemp( response.response );
        await setEdit(false);
    }

    return (
        <main className={classes.content}>
            <div className={classes.toolbar}>
                {edit ?
                    <>
                    <Paper className={classes.tableHome}>
                        <Container  style={{padding: '20px', position: 'relative', display: 'inline-block'}}>
                            <SearchIcon style={{position: 'absolute', left: 200, top: 50, width: 20, height: 20}}/> 
                            <TextField                             
                                onChange={handleSubmit}
                                autoComplete='off'
                                id='search'
                                label='Pesquisar' 
                                placeholder='...'
                                style={{display: 'inline-flex', margin: 8}} 
                                InputLabelProps={{
                                    shrink: true,
                                }}/> 
                                <Button 
                                    onClick={handledAdd}
                                    startIcon={<AddIcon/>}
                                    className={classes.addButton}
                                    style={theme.palette.primary}
                                >  
                                    Adicionar
                                </Button>
                        </Container>
                        {found ? <TableContent Cell={TableRowPkg} edit={handleEdit} getData={getData} classes={classes} rows={packagesTemp} columns={columns} /> : 
                        <>
                        <Divider/>
                        <Typography className={classes.notFound}>Nenhum resultado encontrado!</Typography>
                        </> }
                    </Paper></>
                    :
                    <PkgEdit getData={getData} setEdit={setEdit} packageTemp={packageTemp}/>
                    }
            </div>
        </main>
    )
}

export default Pkg
