import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import LinkA from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import youcast from '../../resource/youcast.png';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {theme} from '../../util/Theme';
import { useHistory } from 'react-router-dom';
import { submitLogin } from './submitLogin';
import { Paper } from '@material-ui/core';


function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <LinkA color="inherit" href="https://youcast.tv.br/">
          Youcast
        </LinkA>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

export default function SignIn({classes, login}) {
    const history = useHistory();
    const [errorField, setErrorField] = useState(false);
    const [helperTextLogin, setHelperTextLogin] = useState('');
    const [helperTextPassword, setHelperTextPassword] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setHelperTextPassword('');
        setHelperTextLogin('');
        const body = {          
          username : event.target.login.value,
          password : event.target.password.value,                    
        }
        if(body.username === '' ){
          setErrorField(true);
          setHelperTextLogin('Campo login obrigatório');
        }else if(body.password === ''){
          setErrorField(true);
          setHelperTextPassword('Campo senha obrigatório');
        }else{          
          await submitLogin(body).then(async (res) => {
            if(res){
              login();
              history.push('/');
            }else{
              setErrorField(true)
              setHelperTextPassword('Usuário ou senha incorretos');
              setHelperTextLogin('Usuário ou senha incorretos');
            }
          }).catch((error) => {
            console.log(error);
            alert('Alguma coisa incorreta');          
          });
        }
           
    }
    console.log("login")
    return (
      <Container  component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Paper elevation={3} className={classes.login}>
          <img alt='' src={youcast} className={classes.avatar} />          
          <Typography component="h1" variant="h5">
            Entre com sua conta
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              error={errorField}
              helperText={errorField ? helperTextLogin : ''}
              fullWidth
              id="login"
              label="Login"
              name="login"
              autoComplete="login"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              error={errorField}
              helperText={errorField ? helperTextPassword : ''}
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={theme.palette.butons}
              className={classes.submit}
            >
              Logar
            </Button>
            <Grid container>
              <Grid item xs component={Link} to='/email'>
                <Typography variant="body2" style={theme.palette.text}>
                  Esqueceu a senha?
                </Typography>
              </Grid>              
            </Grid>
            <Box mt={8}>
              <Copyright />
            </Box>
          </form>
          </Paper>
        </div>        
      </Container>
    );
}