import { getToken } from "../localStorageHandling/localStorageMethods";
import { apiCall, VENDOR, DELETE, GET, POST, PUT } from "./apiConstants"

export const getVendors = async () => {
    try {
        const token = await getToken();
        const {data} = await apiCall(VENDOR, {Authorization: token}, '', GET);
        return data;
    } catch (error) {
        console.log(error);
    }
}


export const getVendorById = async (id) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(VENDOR+'/'+id, {Authorization: token}, '', GET)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const saveVendor = async (body) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(VENDOR, {Authorization: token}, body, POST)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const updateVendor = async (body) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(VENDOR, {Authorization: token}, body, PUT)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const deleteVendor = async (id) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(VENDOR+'?id='+id, {Authorization: token}, '', DELETE);
        return data;
    } catch (error) {
        console.log(error);
    }
}