import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GroupIcon from '@material-ui/icons/Group';
import { theme } from '../../util/Theme'; 

export const userItens = [
    {
        title: 'Home',
        path: '/home',
        icon: <HomeIcon style={theme.palette.secondary} />,
    },
    {
        title: 'Perfil',
        path: '/profile',
        icon: <PersonIcon style={theme.palette.secondary} />,
    }
];

export const adminItems = [
    {
        title: 'Programadoras',
        path: '/pkgowner',
        icon: <BusinessIcon style={theme.palette.secondary} />,
    },
    {
        title: 'Pacotes',
        path: '/pkg',
        icon: <BusinessCenterIcon  style={theme.palette.secondary}/>,
    },
    {
        title: 'Vendors',
        path: '/vendors',
        icon: <AccountBoxIcon style={theme.palette.secondary}/>,
    },
    {
        title: 'Usuários',
        path: '/users',
        icon: <GroupIcon style={theme.palette.secondary}/>,
    },
    
]
