import { getToken } from "../localStorageHandling/localStorageMethods";
import { apiCall, DELETE, GET, PKG_OWNER, POST, PUT } from "./apiConstants";

export const getPkgOnwer = async () => {
    try {
        const token = await getToken();
        const {data} = await apiCall(PKG_OWNER, {Authorization: token}, '', GET);
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const getPkgOnwerById = async (id) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(PKG_OWNER+'/'+id, {Authorization: token}, '', GET)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const savePkgOnwer = async (body) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(PKG_OWNER, {Authorization: token}, body, POST)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const updatePkgOnwer = async (body) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(PKG_OWNER, {Authorization: token}, body, PUT)
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const deletePkgOnwer = async (id) => {
    try {
        const token = await getToken();
        const {data} = await apiCall(PKG_OWNER+'?id='+id, {Authorization: token}, '', DELETE);
        return data;
    } catch (error) {
        console.log(error);
    }
}