
import Paper from '@material-ui/core/Paper';
import { useEffect } from 'react';
import { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Container, Divider, TextField, Typography } from '@material-ui/core';
import TableContent from '../../Components/TableContent';
import TableRowHome from '../../Components/ComponentTableCell/TableRowHome'
import { getPackageOwner, getUserPackages } from '../../util/api/methodsPackagesManagement';

//------------------------
const columns = [
    { id: 'id', label: 'Id', minWidth: 30, align: 'center' },
    { id: 'name', label: 'Vendor', minWidth: 100, align: 'center' },
    { id: 'packages', label: 'Pacotes', minWidth: 170 },
    { id: 'ativo', label: 'Ação', minWidth: 50, align: 'center' },
  ];    
//------------------------

const Home = ({ classes }) => {    
    const [packages, setPackages] = useState([]);
    const [packagesTemp, setPackagesTemp] = useState([]);
    const [found, setFound] = useState(true);

    useEffect(() => {
        getData().then(data => {
            setPackages(data.response);
            setPackagesTemp(data.response);
        }).catch(
            error =>
            console.log(error)
        );        
    }, []);
    
    const getData = async () =>{
        const id = await getPackageOwner();
        const data = await getUserPackages(id);
        setPackages(data.response);
        setPackagesTemp(data.response);
        return data;
    }

    const handleSubmit = async (event) =>{
        event.preventDefault(); 
        const value = event.target.value;
        if(value === ''){
            setPackagesTemp(packages);
        }else{
            var search = packagesTemp.filter(d => d.name.toUpperCase().indexOf(value.toUpperCase()) > -1 );
            
            if(search.length === 0){
                setFound(false);
            }else{
                setPackagesTemp(search);
                setFound(true);
            }
        }
    }

    return (
        <main className={classes.content}>
            <div className={classes.toolbar}>
                <Paper className={classes.tableHome}>
                    <Container  style={{padding: '20px', position: 'relative', display: 'inline-block'}}>
                        <SearchIcon style={{position: 'absolute', left: 200, top: 50, width: 20, height: 20}}/> 
                        <TextField
                            onChange={handleSubmit}
                            autoComplete='off'
                            id='search'
                            label='Pesquisar' 
                            placeholder='...'
                            style={{display: 'inline-flex', margin: 8}} 
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                    </Container>
                    {found ? <TableContent Cell={TableRowHome} getData={getData} classes={classes} rows={packagesTemp} columns={columns} /> 
                    :
                    <>
                    <Divider/>
                    <Typography style={{fontWeight: 'bold', marginTop: '10px', display: 'flex', justifyContent: 'center' }}>Nenhum resultado encontrado!</Typography>
                    </> }
                </Paper>            
            </div>
        </main> 
    )
}

export default Home
