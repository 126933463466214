import { Avatar, Button, Paper } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { theme } from '../../util/Theme';
import { getUser, saveUser } from '../../util/localStorageHandling/localStorageMethods';
import { editProfile, getProfileInfo } from '../../util/api/methodsUsers';

var initialUser = {
    username:'',
    profileName: '',
    packageOwner:{
      name: ''
    }
  }

function Profile({classes}) {
    const [user, setUser] = useState(initialUser);
    const [errorField, setErrorField] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect( () =>{
        let mounted = true;
        if(mounted){
          getUser().then(data => {
            if(data != null || typeof data != 'undefined'){
              setUser(data);
            }
          });
        }
        return ()=> mounted = false;  
    }, []);

    const setPassword1 = async (event) => {
        setPasswordOne(event.target.value);
        if(passwordTwo !== event.target.value){
            setIsDisabled(true);
            setErrorField(true);
            setErrorText('Senhas não são iguais!');
        }else{
            setIsDisabled(false);
            setErrorField(false);
            setErrorText('');
        }
    }

    const handleConfirm = (event) => {
        setPasswordTwo(event.target.value);
        if(passwordOne !== event.target.value){
            setIsDisabled(true);
            setErrorField(true);
            setErrorText('Senhas não são iguais!');        
        }else if(event.target.value === ''){
            setIsDisabled(true);
            setErrorField(true);
            setErrorText('Senhas não são iguais!');
        }
        else{
            setIsDisabled(false);
            setErrorField(false);
            setErrorText('');
        }        
    }

    const submitChange = async (event) => {
        event.preventDefault();
        const body = {
            profileName: event.target.nome.value,
            password: event.target.passwordCheck.value,
        }
        const data = await editProfile(body);
        if(data.status === 1){
            await getProfileInfo()
                .then(data => saveUser(data.data.response))
                .catch(error => saveUser(error));
            window.location.reload()
        }
        
    }

    return (
        <main className={classes.content}>
            <div className={classes.perfil}>
                <Paper className={classes.paperPerfil}>
                    <Avatar style={{width: '40px', height: '40px', marginTop: '10px'}}>
                        <PermIdentityIcon />
                    </Avatar>
                    <form noValidate className={classes.form} onSubmit={(event)=>{
                        event.preventDefault();
                        if(!errorField){
                            if(window.confirm('Tem certeza?'))
                                submitChange(event);
                        } 
                    }}>                        
                        <TextField 
                            variant='outlined'
                            id='login'
                            label='Login'
                            fullWidth
                            margin='dense' 
                            inputProps={{min: 0, style: { textAlign: 'center' }}} 
                            value={user.username}
                            InputLabelProps={{ shrink: true }}
                            disabled/>
                        <TextField 
                            margin='dense' 
                            variant='outlined' 
                            fullWidth 
                            id='nome'
                            label='Nome'
                            autoComplete='off'
                            placeholder={user.profileName}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{min: 0, style: { textAlign: 'center' }}}
                            />
                        <TextField 
                            type='password'
                            margin='dense' 
                            variant='outlined' 
                            autoComplete='off'
                            fullWidth
                            id='password' 
                            label='Senha' 
                            placeholder='*********'   
                            onChange={setPassword1}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{min: 0, style: { textAlign: 'center' }}} 
                            />
                        <TextField 
                            type='password'
                            margin='dense' 
                            variant='outlined' 
                            autoComplete='off'
                            fullWidth
                            id='passwordCheck' 
                            placeholder='*********'
                            label='Confirme a senha' 
                            onChange={handleConfirm}
                            error={errorField}
                            helperText={errorField ? errorText : ''}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{min: 0, style: { textAlign: 'center' }}} 
                            />
                        <Button
                            disabled={isDisabled}                        
                            type='submit'
                            fullWidth
                            variant='contained'
                            style={!isDisabled?theme.palette.butons:theme.palette.buttonDisabled}
                            className={classes.submit}
                        >
                        Alterar
                        </Button>
                    </form>                    
                </Paper>
            </div>
        </main>        
    )
}

export default Profile;