import { Button, Paper, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { saveVendor, updateVendor } from '../../util/api/methodsVendor';
import useStyles from '../../util/StyleMUI';

const VendorEdit = (props) => {
    const classes = useStyles();
    const {setEdit} = props;
    const {vendorEdit} = props;
    const {getData} = props;

    const [isEmptyName, setIsEmptyName] = useState(false);    
    const [isEmptyUserToken, setIsEmptyUserToken] = useState(false);
    const [isEmptyToken, setIsEmptyToken] = useState(false);
    const [isEmptyUrl, setIsEmptyUrl] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const [errorTextName, setErrorTextName] = useState('');
    const [errorTextUserToken, setErrorTextUserToken] = useState('');
    const [errorTextToken, setErrorTextToken] = useState('');
    const [errorTextUrl, setErrorTextUrl] = useState('');


    
    const handleSave = async () => {
        if(vendorEdit.id === 0){
            //console.log(vendorEdit);
            const data = await saveVendor(vendorEdit);
            //console.log(data)
            if(data.status === 1){
                await setEdit(true);
                await getData();
            }else if (data.status === 2){
                setErrorTextName('Vendor já existe!');
                setIsEmptyName(true);
            }else if (data.status === 3){
                setErrorTextName('Campos obrigatórios');
                setIsEmptyName(true);
                setIsEmptyUserToken(true);
                setDisableButton(true);
                setIsEmptyToken(true);
                setIsEmptyUrl(true);
            }else {
                //console.log(data)
                window.alert("Alguma coisa incorreta!")                
            }
        }else{
            //console.log(vendorEdit);
            const data = await updateVendor(vendorEdit);
            if(data.status === 1){
                await setEdit(true);
                await getData();
            }else if (data.status === 2){
                setErrorTextName('Vendor já existe!');
                setIsEmptyName(true);
            }else if (data.status === 3){
                setErrorTextName('Campos obrigatórios');
                setDisableButton(true);                
                setIsEmptyName(true);
                setIsEmptyUserToken(true);
                setIsEmptyToken(true);
                setIsEmptyUrl(true);
            }else{
                //console.log(data)
                window.alert("Alguma coisa incorreta!")                
            }
        }
    }

    const onChangeName = (event) => {
        if(event.target.value === ''){
            setErrorTextName('Campo obrigatório!');
            setDisableButton(true);
            setIsEmptyName(true);
        }else{
            setErrorTextName('');
            setIsEmptyName(false);
            setDisableButton(false);
            vendorEdit.name = event.target.value;
        }
    }

    const onChangeUserToken = (event) => {
        if(event.target.value === ''){
            setErrorTextUserToken('Campo obrigatório!');
            setDisableButton(true);
            setIsEmptyUserToken(true);
        }else{
            setErrorTextUserToken('');
            setDisableButton(false);
            setIsEmptyUserToken(false);
            vendorEdit.userToken = event.target.value;
        }
    }
    
    const onChangeToken = (event) => {
        if(event.target.value === ''){
            setErrorTextToken('Campo obrigatório!');
            setDisableButton(true);
            setIsEmptyToken(true);
        }else{
            setErrorTextToken('');
            setIsEmptyToken(false);
            setDisableButton(false);
            vendorEdit.token = event.target.value;
        }
    }
    
    const onChangeUrl = (event) => {
        if(event.target.value === ''){
            setErrorTextUrl('Campo obrigatório!');
            setDisableButton(true);
            setIsEmptyUrl(true);
        }else{
            setErrorTextUrl('');
            setIsEmptyUrl(false);
            setDisableButton(false);
            vendorEdit.url = event.target.value;
        }
    }

    return (
        <Paper className={classes.editBack}>
            <div className={classes.edit}>                
                <Paper elevation={3} className={classes.editPaper}>
                    { vendorEdit.id !== 0 ? <TextField                     
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='id'
                        label='ID'
                        autoComplete='off'
                        value={vendorEdit.id}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        /> : '' }
                    <TextField 
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='nome'
                        label='Nome do vendor:'
                        autoComplete='off'
                        error={isEmptyName}
                        helperText={isEmptyName ? errorTextName : ''}
                        defaultValue={vendorEdit.name}
                        onChange={onChangeName}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        />
                    <TextField 
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='userToken'
                        label='Usuário do MW:'
                        autoComplete='off'
                        error={isEmptyUserToken}
                        helperText={isEmptyUserToken ? errorTextUserToken : ''}
                        defaultValue={vendorEdit.userToken}
                        onChange={onChangeUserToken}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        />
                    <TextField 
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='token'
                        label='API Secret MW:'
                        autoComplete='off'
                        error={isEmptyToken}
                        helperText={isEmptyToken ? errorTextToken : ''}
                        defaultValue={vendorEdit.token}
                        onChange={onChangeToken}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        />
                    <TextField 
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='url'
                        label='Endereço do MW (Sem https://):'
                        autoComplete='off'
                        error={isEmptyUrl}
                        helperText={isEmptyUrl ? errorTextUrl : ''}
                        defaultValue={vendorEdit.url}
                        onChange={onChangeUrl}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        />
                </Paper>
                <div elevation={3} style={{padding: '10px', textAlign: 'right'}}>
                    <Button disabled={disableButton} onClick={handleSave} className={classes.editButton} style={{width: '100px', alignSelf:'right'}} >Salvar</Button>
                    <Button onClick={()=> setEdit(true)} className={classes.deleteButton} style={{width: '100px', marginLeft:'5px' ,alignSelf:'right'}} >Cancel</Button>
                </div>              
            </div>
        </Paper>
    )
}

export default VendorEdit
