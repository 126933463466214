import { getToken, getUser } from "../localStorageHandling/localStorageMethods";
import { apiCall, GET, PKG_MGNT, POST } from "./apiConstants";

export const getUserPackages = async (pkgID) => {
    const user = await getUser();
    if(user.admin === 0){
        pkgID = user.packageOwner.id;
    }
    const token = await getToken();
    try {
        const { data } = await apiCall(PKG_MGNT+'?pgkId='+pkgID,{Authorization: token}, '', GET);
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const changeStatus = async (getData, vendor, status) => {
    await getData();
    const token = await getToken();
    const pkgOnwer = await getPackageOwner();
    const queryVendor = 'vendor='+vendor;
    const queryStatus = 'status='+status;
    const queryPkgOwner = 'pkgOwner='+pkgOnwer;

    //console.log(queryPkgOwner, queryStatus, queryVendor);

    try {
        const { data } = await apiCall(PKG_MGNT+'?'
                                        +queryVendor+'&'
                                        +queryStatus+'&'
                                        +queryPkgOwner,
                                        {Authorization: token}, '', POST);
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const getPackageOwner = async () => {
    const user = await getUser();    
    return user.packageOwner.id;
}