import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { getPkgOnwer } from '../../util/api/methodsPackageOwner';
import { saveUser, updateUser } from '../../util/api/methodsUsers';
import useStyles from '../../util/StyleMUI';

const UserEdit = (props) => {    
    const classes = useStyles();
    const {getData} = props;
    const {userEdit} = props;
    const {setEdit} = props;

    const [pkgOwnerList, setPkgOwnerList] = useState([userEdit.packageOwner]);
    const [isChecked, setIsChecked] = useState(userEdit.admin === 1);
    const [pkgOwnerSelected, setPkgOwnerSelected] = useState(userEdit.packageOwner.id);
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    
    const [isEmptyProfileName, setIsEmptyProfileName] = useState(false);    
    const [isEmptyUserName, setIsEmptyUserName] = useState(false);
    const [isEmptyEmail, setIsEmptyEmail] = useState(false);
    const [isEmptyPackageOwner, setIsEmptyPackageOwner] = useState(false);
    const [isEmptyPassword, setIsEmptyPassword] = useState(false);
    const [disableButton, setDisableButton] = useState(false);    

    const [errorTextProfileName, setErrorTextProfileName] = useState('');
    const [errorTextUserName, setErrorTextUserName] = useState('');
    const [errorTextEmail, setErrorTextEmail] = useState('');
    const [errorTextPassword, setErrorTextPassword] = useState(false);


    useEffect(() => {
        getPkgOnwer().then(data => setPkgOwnerList(data.response));
    }, [])


    const onChangeProfileName = (event) => {
        if(event.target.value === ''){
            setErrorTextProfileName('Campo obrigatório!');
            setDisableButton(true);
            setIsEmptyProfileName(true);
        }else{
            setErrorTextProfileName('');
            setIsEmptyProfileName(false);
            setDisableButton(false);
            userEdit.profileName = event.target.value;
        }
    }

    const onChangeUserName = (event) => {
        if(event.target.value === ''){
            setErrorTextUserName('Campo obrigatório!');
            setDisableButton(true);
            setIsEmptyUserName(true);
        }else{
            setErrorTextUserName('');
            setIsEmptyUserName(false);
            setDisableButton(false);
            userEdit.username = event.target.value;
        }
    }

    const onChangeEmail = (event) => {
        if(event.target.value === ''){            
            setErrorTextEmail('Campo obrigatório!');
            setDisableButton(true);
            setIsEmptyEmail(true);
        }else{
            setErrorTextEmail('');
            setIsEmptyEmail(false);
            setDisableButton(false);
            userEdit.email = event.target.value;
        }
    }

    const onChangeAdmin = (event) => {
        if(userEdit.admin === 1){
            setIsChecked(false);
            userEdit.admin = 0;
        }else{
            setIsChecked(true);
            userEdit.admin = 1;
        }
        console.log(userEdit.admin)
    }
    
    const handleSelectPkgOwner = (event) => {
        if(event.target.value === 0){
            setDisableButton(true);
            setIsEmptyPackageOwner(true);
            userEdit.packageOwner.id = event.target.value;
            setPkgOwnerSelected(event.target.value);
        }else{
            setIsEmptyPackageOwner(false);
            setDisableButton(false);
            userEdit.packageOwner.id = event.target.value;
            setPkgOwnerSelected(event.target.value);
        }
    }

    const handleSave = async () => {
        if(userEdit.id === 0){
            if(passwordOne !== '' ){
                userEdit.password = passwordOne;
            }
            const data = await saveUser(userEdit);
            if(data.status === 1){
                await setEdit(true);
                await getData();
            }else if (data.status === 2){
                setErrorTextProfileName('Usuário já existe!');
                setIsEmptyProfileName(true);
            }else if (data.status === 3){
                setErrorTextProfileName('Campos obrigatórios');
                setIsEmptyProfileName(true);
                setIsEmptyUserName(true);
                setDisableButton(true);
                setIsEmptyEmail(true);
                setIsEmptyPackageOwner(true);
            }else if(data.status === 6){
                setErrorTextEmail('Email inválido!');
                setDisableButton(true);
                setIsEmptyEmail(true);
            }else{
                console.log(data)
                window.alert("Alguma coisa incorreta!")                
            }
        }else{
            if(passwordOne !== '' ){
                userEdit.password = passwordOne;
            }
            const data = await updateUser(userEdit);
            if(data.status === 1){
                await setEdit(true);
                await getData();
            }else if (data.status === 2){
                setErrorTextProfileName('Usuário já existe!');
                setIsEmptyProfileName(true);
            }else if (data.status === 3){
                setErrorTextProfileName('Campos obrigatórios');
                setIsEmptyProfileName(true);
                setIsEmptyUserName(true);
                setDisableButton(true);
                setIsEmptyEmail(true);
                setIsEmptyPackageOwner(true);
            }else {
                console.log(data)
                window.alert("Alguma coisa incorreta!")                
            }
        }
    }


    //----------------------password confirmation----------------

    const setPassword1 = async (event) => {
        setPasswordOne(event.target.value);
        if(passwordTwo !== event.target.value){
            setDisableButton(true);
            setIsEmptyPassword(true);
            setErrorTextPassword('Senhas não são iguais!');
        }else if(event.target.value === ''){
            setDisableButton(true);
            setIsEmptyPassword(true);
            setErrorTextPassword('Senhas não são iguais!');
        }
        else{
            setDisableButton(false);
            setIsEmptyPassword(false);
            setErrorTextPassword('');
        }
    }

    const handleConfirm = (event) => {
        setPasswordTwo(event.target.value);
        if(passwordOne !== event.target.value){
            setDisableButton(true);
            setIsEmptyPassword(true);
            setErrorTextPassword('Senhas não são iguais!');        
        }else if(event.target.value === ''){
            setDisableButton(true);
            setIsEmptyPassword(true);
            setErrorTextPassword('Senhas não são iguais!');
        }
        else{
            setDisableButton(false);
            setIsEmptyPassword(false);
            setErrorTextPassword('');
        }        
    }

    //----------------------password confirmation----------------

    return (
        <Paper className={classes.editBack}>
            <div className={classes.edit}>                
                <Paper elevation={3} className={classes.editPaper}>
                    { userEdit.id !== 0 ? <TextField
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='id'
                        label='ID'
                        autoComplete='off'
                        value={userEdit.id}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        /> : '' }
                    <TextField 
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='username'
                        label='Login:'
                        autoComplete='off'
                        error={isEmptyUserName}
                        helperText={isEmptyUserName ? errorTextUserName : ''}
                        defaultValue={userEdit.username}
                        onChange={onChangeUserName}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        />
                    <TextField 
                        margin='dense' 
                        label='Senha'
                        type='password'
                        variant='outlined' 
                        id='password'
                        onChange={setPassword1}
                        style={{width: '80%'}}
                        placeholder='**********'
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                    />
                    <TextField 
                        margin='dense' 
                        label='Confirme a sua senha'
                        style={{marginTop: '10px'}}
                        type='password'
                        variant='outlined' 
                        id='confirm'
                        style={{width: '80%'}}
                        placeholder='**********'
                        onChange={handleConfirm}
                        error={isEmptyPassword}
                        helperText={isEmptyPassword ? errorTextPassword : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                    />

                    <TextField 
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='nome'
                        label='Nome de perfil:'
                        autoComplete='off'
                        error={isEmptyProfileName}
                        helperText={isEmptyProfileName ? errorTextProfileName : ''}
                        defaultValue={userEdit.profileName}
                        onChange={onChangeProfileName}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        />
                    <TextField 
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='email'
                        label='Email:'
                        autoComplete='off'
                        error={isEmptyEmail}
                        helperText={isEmptyEmail ? errorTextEmail : ''}
                        defaultValue={userEdit.email}
                        onChange={onChangeEmail}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        />

                    {/*PkgOwner Selector begin==========================================*/}

                    <FormControl margin='dense'  variant="outlined" style={{width: '80%', marginTop: '10px'}} error={isEmptyPackageOwner}>
                        <InputLabel >{ isEmptyPackageOwner ? 'Programadora inválida!' : 'Programadora'}</InputLabel>
                        <Select
                        value={pkgOwnerSelected}
                        onChange={handleSelectPkgOwner}
                        label={ isEmptyPackageOwner ? 'Programadora inválida!' : 'Programadora'}
                        >
                        <MenuItem value={0}>
                            <em>N/D</em>
                        </MenuItem>
                        {pkgOwnerList.map(pkgOwner => <MenuItem key={pkgOwner.id} value={pkgOwner.id}>{pkgOwner.name}</MenuItem>)}
                        </Select>
                    </FormControl>

                    {/*PkgOwner Selector end==========================================*/}  

                    <FormControlLabel
                            labelPlacement="start"
                            control={
                            <Checkbox
                                checked={isChecked}
                                onChange={onChangeAdmin}
                                name="checkedB"
                                style={{color: '#d1aa00'}}
                            />
                            }
                            label="Administrador"
                        />
                </Paper>
                <div elevation={3} style={{padding: '10px', textAlign: 'right'}}>
                    <Button disabled={disableButton} onClick={handleSave} className={classes.editButton} style={{width: '100px', alignSelf:'right'}} >Salvar</Button>
                    <Button onClick={()=> setEdit(true)} className={classes.deleteButton} style={{width: '100px', marginLeft:'5px' ,alignSelf:'right'}} >Cancel</Button>
                </div>              
            </div>
        </Paper>
    )
}

export default UserEdit
