import { Button, Paper, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { savePkgOnwer, updatePkgOnwer } from '../../util/api/methodsPackageOwner';
import useStyles from '../../util/StyleMUI';

const PkgOwnerEdit = (props) => {
    const classes = useStyles();
    const {setEdit} = props;
    const {pkgOwner} = props;
    const {getData} = props;
    const [errorField, setErrorField] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [errorText, setErrorText] = useState('');

    const handleSave = async () => {
        if(pkgOwner.id === 0){
            const data = await savePkgOnwer(pkgOwner);
            if(data.status === 1){
                await setEdit(true);
                await getData();
            }else if (data.status === 2){
                setErrorField(true);
                setErrorText('Programadora já existe!')
            }else if (data.status === 3){
                setErrorField(true);
                setErrorText('Campo obrigatório!')
            }else{
                window.alert("Alguma coisa incorreta!")                
            }
        }else{
            const data = await updatePkgOnwer(pkgOwner);
            if(data.status === 1){
                await setEdit(true);
                await getData();
            }else if (data.status === 2){
                setErrorField(true);
                setErrorText('Programadora já existe!')
            }else if (data.status === 3){
                setErrorField(true);
                setErrorText('Campo obrigatório!')
            }else{
                window.alert("Alguma coisa incorreta!")                
            }
        }
    }

    const handleOnChange = (event) => {
        if(event.target.value === ''){
            setErrorField(true);
            setErrorText('Campo obrigatório!')
            setDisableButton(true);
        }else{
            setErrorField(false);
            setErrorText('');
            setDisableButton(false);
            pkgOwner.name = event.target.value;
        }
    }

    return (
        <Paper className={classes.editBack}>
            <div className={classes.edit}>                
                <Paper elevation={3} className={classes.editPaper}>
                    { pkgOwner.id !== 0 ? <TextField                     
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='id'
                        label='ID'
                        autoComplete='off'
                        value={pkgOwner.id}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        /> : '' }
                    <TextField 
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='nome'
                        label='Nome da programadora:'
                        autoComplete='off'
                        error={errorField}
                        helperText={errorField ? errorText : ''}
                        onChange={handleOnChange}
                        placeholder='Ex: GloboSat'
                        defaultValue={pkgOwner.name}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        />
                </Paper>
                <div elevation={3} style={{padding: '10px', textAlign: 'right'}}>
                    <Button disabled={disableButton} onClick={handleSave} className={classes.editButton} style={{width: '100px', alignSelf:'right'}} >Salvar</Button>
                    <Button onClick={()=> setEdit(true)} className={classes.deleteButton} style={{width: '100px', marginLeft:'5px' ,alignSelf:'right'}} >Cancel</Button>
                </div>              
            </div>
        </Paper>
    )
}

export default PkgOwnerEdit
