import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { getMotvPackages, savePackage, updatePackage } from '../../util/api/methodsPackages';
import useStyles from '../../util/StyleMUI';
import { getPkgOnwer } from '../../util/api/methodsPackageOwner';
import { getVendors } from '../../util/api/methodsVendor';

var initialPkgOwnerList = [{id:0, name:'', }];
var initialVendorList = [{id:0, name:'', }]

const PkgEdit = (props) => {
    const classes = useStyles();
    const {setEdit} = props;
    const {getData} = props;
    const {packageTemp} = props;
    const [errorFieldPkg, setErrorFieldPkg] = useState(false);
    const [errorFieldVendor, setErrorFieldVendor] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    
    const [pkgList, setPkgList] = useState(initialVendorList);
    const [vendorList, setVendorList] = useState(initialVendorList);
    const [pkgOwnerList, setPkgOwnerList] = useState(initialPkgOwnerList);

    const [pkgId, setPkgId] = useState(packageTemp.mwId);
    const [vendorId, setVendorId] = useState(packageTemp.vendor.id);
    const [pkgOwnerID, setPkgOwnerID] = useState(packageTemp.packageOwner.id);
    
    useEffect(() => {
        if(packageTemp.vendor.id !== 0){
            getDataFromMw(packageTemp.vendor.id);
            setPkgId(packageTemp.mwId)
        }
    },[])

    useEffect(() => {
        let mounted = true;
        getVendors().then(
        data => {
            if(mounted){
                setVendorList(data.response);
            }
        }
        )
        return ()=>mounted=false;
    }, [])

    useEffect(() => {
        let mounted = true;
        getPkgOnwer().then(
        data => {
            if(mounted){
                setPkgOwnerList(data.response);
            }
        }
        )
        return ()=>mounted=false;
    }, [])


    const handleSave = async () => {
        if(packageTemp.id === 0){
            const pkgName = pkgList.filter(p => p.id === pkgId );
            packageTemp.name = pkgName[0].name;
            packageTemp.mwId = parseInt(pkgId);
            const data = await savePackage(packageTemp);
            if(data.status === 1){
                await setEdit(true);
                await getData();
            }else if (data.status === 2){
                setErrorFieldPkg(true);
                setErrorFieldVendor(true);
            }else if (data.status === 3){
                setErrorFieldPkg(true);
                setErrorFieldVendor(true);
                setDisableButton(true);
            }else{
                window.alert("Alguma coisa incorreta!")                
            }
        }else{
            const pkgName = pkgList.filter(p => p.id === pkgId.toString() );
            packageTemp.name = pkgName[0].name;
            packageTemp.mwId = parseInt(pkgId);
            const data = await updatePackage(packageTemp);
            if(data.status === 1){
                await setEdit(true);
                await getData();
            }else if (data.status === 2){
                setErrorFieldPkg(true);
                setErrorFieldVendor(true);
            }else if (data.status === 3){
                setErrorFieldPkg(true);
                setErrorFieldVendor(true);
                setDisableButton(true);
            }else{
                window.alert("Alguma coisa incorreta!")                
            }
        }
    }
    
    const handleSelectPkgOwner = async (event) => {
        setErrorFieldPkg(false);
        setErrorFieldVendor(false);
        setDisableButton(false);
        setPkgOwnerID(event.target.value);
        packageTemp.packageOwner.id = event.target.value;
        packageTemp.packageOwner.name = '';
    }

    const handleSelectVendor = async (event) => {
        setErrorFieldPkg(false);
        setErrorFieldVendor(false);
        setDisableButton(false);
        setVendorId(event.target.value);
        packageTemp.vendor.id = event.target.value;
        packageTemp.vendor.name = '';
        packageTemp.vendor.url = '';
        await getDataFromMw(event.target.value);
    }

    const getDataFromMw = async (idVendor) => {
        if(idVendor !== 0){
            const data = await getMotvPackages(idVendor);
            if(data.status === 1){
                await setPkgList(data.response);
            }else{
                await setPkgList([]);    
            }
        }else{
            await setPkgList([]);
        }
    }

    const handleSelectPackageMotv = async (event) => {
        setPkgId(event.target.value);
        console.log(event.target.value);
    }

    return (
        <Paper className={classes.editBack}>
            <div className={classes.edit}>                
                <Paper elevation={3} className={classes.editPaper}>
                    { packageTemp.id !== 0 ? <TextField                     
                        margin='dense' 
                        variant='outlined' 
                        style={{width: '80%'}}
                        id='id'
                        label='ID'
                        autoComplete='off'
                        value={packageTemp.id}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        /> : '' }

                    {/*Vendor Selector==========================================*/}

                    <FormControl margin='dense'  variant="outlined" style={{width: '80%', marginTop: '10px'}} error={errorFieldVendor}>
                        <InputLabel >{ errorFieldVendor ? 'Vendor inválido!' : 'Vendor'}</InputLabel>
                        <Select
                        value={vendorId}
                        onChange={handleSelectVendor}
                        label={ errorFieldVendor ? 'Vendor inválido!' : 'Vendor'}
                        >
                        <MenuItem value={0}>
                            <em>N/D</em>
                        </MenuItem>
                        {vendorList.map(vendor => <MenuItem key={vendor.id} value={vendor.id}>{vendor.name}</MenuItem>)}
                        </Select>
                    </FormControl>

                    {/*Package MW Selector==========================================*/}

                    <FormControl margin='dense'  variant="outlined" style={{width: '80%', marginTop: '10px'}} error={vendorId === 0}>
                        <InputLabel >{ vendorId === 0 ? 'Selecione um Vendor!' : 'Pacote'}</InputLabel>
                        <Select
                        value={pkgId}
                        onChange={handleSelectPackageMotv}
                        label={ vendorId === 0 ? 'Selecione um Vendor!' : 'Pacote'}
                        >
                        <MenuItem value={0}>
                            <em>N/D</em>
                        </MenuItem>
                        {pkgList.map(pkg => <MenuItem key={pkg.id} value={pkg.id}>{pkg.name}</MenuItem>)}
                        </Select>
                    </FormControl>    
                    
                    {/*PkgOwner Selector==========================================*/}

                    <FormControl margin='dense'  variant="outlined" style={{width: '80%', marginTop: '10px'}} error={errorFieldPkg}>
                        <InputLabel >{ errorFieldPkg ? 'Programadora inválida!' : 'Programadora'}</InputLabel>
                        <Select
                        value={pkgOwnerID}
                        onChange={handleSelectPkgOwner}
                        label={ errorFieldPkg ? 'Programadora inválida!' : 'Programadora'}
                        >
                        <MenuItem value={0}>
                            <em>N/D</em>
                        </MenuItem>
                        {pkgOwnerList.map(pkg => <MenuItem key={pkg.id} value={pkg.id}>{pkg.name}</MenuItem>)}
                        </Select>
                    </FormControl>

                </Paper>
                <div elevation={3} style={{padding: '10px', textAlign: 'right'}}>
                    <Button disabled={disableButton} onClick={handleSave} className={classes.editButton} style={{width: '100px', alignSelf:'right'}} >Salvar</Button>
                    <Button onClick={()=> setEdit(true)} className={classes.deleteButton} style={{width: '100px', marginLeft:'5px' ,alignSelf:'right'}} >Cancel</Button>
                </div>              
            </div>
        </Paper>
    )
}

export default PkgEdit
